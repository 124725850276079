import React from "react";
import classes from "./Hours.module.scss";
import { useTranslation } from "react-i18next";
 
const Hours = () => {
  const { t } = useTranslation();
  return (
    <div className={[classes.hours, ""].join(" ")}>
      <div className={[classes.hoursBody, ""].join(" ")}>
        <div className="container">
          <div className={[classes.hoursTitle, "font-42"].join(" ")}>
            {t('trading_hours_title')}
          </div>
          <div className={[classes.hoursRow, ""].join(" ")}>
            <div className={[classes.hoursRowColumn, ""].join(" ")}>
              <div className={[classes.hoursRowColumnItem, ""].join(" ")}>
                <div
                  className={[classes.hoursRowColumnItemImg, ""].join(" ")}
                ></div>
                <div
                  className={[classes.hoursRowColumnItemTitle, "font-20"].join(
                    " "
                  )}
                >
                  {t('trading_hours_subtitle_01')}
                </div>

                <div className={[classes.hoursRowColumnItemList, ""].join(" ")}>
                  <div
                    className={[
                      classes.hoursRowColumnItemListItem,
                      "font-18",
                    ].join(" ")}
                  >
                    {t('trading_hours_text_01')}
                  </div>
                  <div
                    className={[
                      classes.hoursRowColumnItemListItem,
                      "font-18",
                    ].join(" ")}
                  >
                    {t('trading_hours_text_02')}
                  </div>
                </div>
              </div>
            </div>
            <div className={[classes.hoursRowColumn, ""].join(" ")}>
              <div className={[classes.hoursRowColumnItem, ""].join(" ")}>
                <div
                  className={[classes.hoursRowColumnItemImg, ""].join(" ")}
                ></div>
                <div
                  className={[classes.hoursRowColumnItemTitle, "font-20"].join(
                    " "
                  )}
                >
                  {t('trading_hours_subtitle_02')}
                </div>

                <div className={[classes.hoursRowColumnItemList, ""].join(" ")}>
                  <div
                    className={[
                      classes.hoursRowColumnItemListItem,
                      "font-18",
                    ].join(" ")}
                  >
                    {t('trading_hours_text_03')}
                  </div>
                  <div
                    className={[
                      classes.hoursRowColumnItemListItem,
                      "font-18",
                    ].join(" ")}
                  >
                    {t('trading_hours_text_02')}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hours;
