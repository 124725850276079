import React from "react";
import classes from './Banner.module.scss';
import mac from '../../../assets/img/EducationPage/Banner/mac.png';
// import bg from '../../../assets/img/EducationPage/Banner/bg.png';
import Button from "../../GlobalComponents/Button/Button";
import { useTranslation } from "react-i18next";

const Banner = () => {
    const { t } = useTranslation();
    return (
        <section className={classes.banner}>
            <div className="container">
                <div className={classes.bannerBody}>
                    <h1 className={[classes.title, 'font-42'].join(' ')}>
                        {t('education_banner_title')}
                    </h1>
                    <div className={classes.btn}>
                        <Button second="true">{t('btn_start')}</Button>
                    </div>
                    {/* <img className={classes.bg} src={bg} alt=''/> */}
                    <img className={classes.mac} src={mac} alt=''/>
                </div>
            </div>
        </section>
    );
};

export default Banner;
