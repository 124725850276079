import React from "react";
import classes from "./Footer.module.scss";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();
  return (
    <div className={classes.footer}>
      <div className="container">
        <div className={classes.content}>
          <div className={classes.info}>
            <div className={classes.row}>
              <p className={classes.rowText}>
                <span>{t('footer_about')}</span>
                {t('footer_about_text')}
              </p>
            </div>
          </div>
          <div className={classes.docs}>
            <div>
              <a href="/docs/TERMS_&_CONDITIONS.pdf" target="_blank">
                {t('footer_terms')}
              </a>
            </div>
            <div>
              <a href="/docs/PRIVACY_POLICY.pdf" target="_blank">
                {t('footer_privacy')}
              </a>
            </div>
            <div>
              <a href="/docs/AML_POLICY.pdf" target="_blank">
                {t('footer_aml')}
              </a>
            </div>
          </div>
          <div className={classes.about}>
            <p>{t('footer_can_contact')}</p>
            <div>
              <p>{t('footer_contact_us')}</p>
              <a href="tel:+441582323136">
                +441582323136
              </a>
              <a href="mailto:support@goldman-group.info">
                support@goldman-group.info
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
