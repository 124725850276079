import React, { useEffect, useRef } from "react";
import classes from "./StartTrading.module.scss";
import { useTranslation } from "react-i18next";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const StartTrading = () => {
  const { t } = useTranslation();
  const startjourneyRef = useRef(null);
  useEffect(() => {
    if (document.documentElement.clientWidth > 1220) {
      gsap.timeline().fromTo(startjourneyRef.current, {
        y: 100,
        opacity: 0,
      }, {
        y: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: startjourneyRef.current,
          end: "+=200",
          scrub: 2,
        }
      });
    }
  }, []);
  return (
    <section className="container">
      <div ref={startjourneyRef} className={classes.startTrading}>
        <h2 className={[classes.title, "font-42"].join(" ")}>
          {t('start_your_journey_title')}
        </h2>
        <div className={classes.steps}>
          <div className={[classes.step, classes.step_01].join(" ")}>
            <div className={classes.stepNum}>
              <span>1</span>
            </div>
            <div className={classes.stepContent}>
              <p className={[classes.stepText, "font-20"].join(" ")}>
                {t('start_your_journey_text_01')}
              </p>
            </div>
          </div>
          <div className={[classes.step, classes.step_02].join(" ")}>
            <div className={classes.stepNum}>
              <span>2</span>
            </div>
            <div className={classes.stepContent}>
              <p className={[classes.stepText, "font-20"].join(" ")}>
                {t('start_your_journey_text_02')}
              </p>
            </div>
          </div>
          <div className={[classes.step, classes.step_03].join(" ")}>
            <div className={classes.stepNum}>
              <span>3</span>
            </div>
            <div className={classes.stepContent}>
              <p className={[classes.stepText, "font-20"].join(" ")}>
                {t('start_your_journey_text_03')}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default StartTrading;
