import React, { useState, useEffect, useRef } from "react";
import classes from './Markets.module.scss';
import { useTranslation } from "react-i18next";
import $ from 'jquery';
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const buttons = [
    {
        id: 1,
        name: 'trading_markets_card_01_title',
    },
    {
        id: 2,
        name: 'trading_markets_card_02_title',
    },
    {
        id: 3,
        name: 'trading_markets_card_03_title',
    },
    {
        id: 4,
        name: 'trading_markets_card_04_title',
    },
    {
        id: 5,
        name: 'trading_markets_card_05_title',
    },
];
const content = {
    1: 'trading_markets_card_01_text',
    2: 'trading_markets_card_02_text',
    3: 'trading_markets_card_03_text',
    4: 'trading_markets_card_04_text',
    5: 'trading_markets_card_05_text',
};

const Markets = () => {
    const { t } = useTranslation();
    const [ currentTab, setCurrentTab ] = useState(1);
    const marketsRef = useRef(null);
    var initSize = function() {
        var start_element = $('.tabs-block li:first-of-type');
        var $label = $('.investAtTabs_label');
        var initWidth = start_element.css('width')	
        $label.css('width', initWidth);
    }
    useEffect(() => {
        initSize();
        $('.tabs-block li').on('click', function(){
            var $label = $('.investAtTabs_label');	
            var $this = $(this);
            var el_width = $this.width();
            var offset = $this.offset();
            $label
                .offset({ left: offset.left })
                .offset({ top: offset.top })
                .css('width', el_width)
        });
    }, []);
    useEffect(() => {
        if (document.documentElement.clientWidth > 1220) {
            gsap.timeline().fromTo(marketsRef.current, {
              y: 100,
              opacity: 0,
            }, {
              y: 0,
              opacity: 1,
              scrollTrigger: {
                trigger: marketsRef.current,
                end: "+=200",
                scrub: 2,
              }
            });
        }
      }, []);
    return (
        <section className="container">
            <div ref={marketsRef} className={classes.markets}>
                <h2 className={classes.title}>
                    {t('trading_markets_title')}
                </h2>
                <p className={classes.text}>
                    {t('trading_markets_text')}
                </p>
                <div className={classes.tabs}>
                    <ul className={`${classes.buttons} tabs-block`}>
                        <div className={`${classes.label} investAtTabs_label`}></div>
                        {buttons.map((btn) =>
                            <li
                                key={btn.id}
                                onClick={() => setCurrentTab(btn.id)}
                                className={`${classes.btn} ${currentTab === btn.id && classes.btnActive}`}
                            >
                                <span>
                                    {t(btn.name)}
                                </span>
                            </li>
                        )}
                    </ul>
                    <div className={classes.content}>
                        <p>
                            {t(content[currentTab])}
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Markets;
