import React from "react";
import classes from './Banner.module.scss';
import img from '../../../assets/img/AboutPage/Banner/img.png';
import { useTranslation } from "react-i18next"; 
import Button from "../../GlobalComponents/Button/Button";

const Banner = () => {
    const { t } = useTranslation();
    return (
        <section className={classes.banner}>
            <img className={classes.image} src={img} alt=''/>
            <div className="container">
                <div className={classes.bannerBody}>
                    <h1 className={[classes.title, 'font-42'].join(' ')}>
                        {t('about_banner_title')}
                    </h1>
                    <p className={[classes.text, 'font-20'].join(' ')}>
                        {t('about_banner_text')}
                    </p>
                    <div className={classes.btn}>
                        <Button second="true">{t('btn_start')}</Button>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Banner;
