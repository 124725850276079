import React, { useEffect, useRef, useState } from "react";
import classes from './Tabs.module.scss';
import graph from '../../../assets/img/EducationPage/Tabs/graph.png';
import { useTranslation } from "react-i18next";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const tabs = [
    {
        id: 1,
        title: 'education_tab_01_title',
        text: 'education_tab_01_text',
    },
    {
        id: 2,
        title: 'education_tab_02_title',
        text: 'education_tab_02_text',
    },
    {
        id: 3,
        title: 'education_tab_03_title',
        text: 'education_tab_03_text',
    },
    {
        id: 4,
        title: 'education_tab_04_title',
        text: 'education_tab_04_text',
    },
    {
        id: 5,
        title: 'education_tab_05_title',
        text: 'education_tab_05_text',
    },
    {
        id: 6,
        title: 'education_tab_06_title',
        text: 'education_tab_06_text',
    },
];

const Tabs = () => {
    const [ currentTab, setCurrentTab ] = useState(1);
    const { t } = useTranslation();
    const tabsEduRef = useRef(null);
    useEffect(() => {
        if (document.documentElement.clientWidth > 1220) {
            gsap.timeline().fromTo(tabsEduRef.current, {
                y: 100,
                opacity: 0,
            }, {
                y: 0,
                opacity: 1,
                scrollTrigger: {
                trigger: tabsEduRef.current,
                end: "+=200",
                scrub: 2,
                }
            });
        }
    }, []);
    return (
        <section className="container">
            <div ref={tabsEduRef} className={classes.tabsWr}>
                <div className={classes.content}>
                    <div className={classes.tabs}>
                        {tabs.map((tab) =>
                            <div 
                                key={tab.id} 
                                className={`${classes.tab} ${tab.id === currentTab && classes.current}`}
                                onClick={() => setCurrentTab(tab.id)}    
                            >
                                <h3 className={[classes.tabTitle, 'font-20'].join(' ')}>
                                    {t(tab.title)}
                                </h3>
                                <p className={`${classes.tabText} font-18`}>
                                    {t(tab.text)}
                                </p>
                            </div>
                        )}
                    </div>
                    <img className={classes.image} src={graph} alt=''/>
                </div>
            </div>
        </section>
    );
};

export default Tabs;
