import React, { useEffect, useRef } from "react";
import classes from './OurGoal.module.scss';
import { useTranslation } from "react-i18next";
import img from '../../../assets/img/AboutPage/OurGoal/img.png';
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const OurGoal = () => {
    const { t } = useTranslation();
    const ourGoalRef = useRef(null);
    useEffect(() => {
        if (document.documentElement.clientWidth > 1220) {
            gsap.timeline().fromTo(ourGoalRef.current, {
                y: 100,
                opacity: 0,
            }, {
                y: 0,
                opacity: 1,
                scrollTrigger: {
                trigger: ourGoalRef.current,
                end: "+=200",
                scrub: 2,
                }
            });
        }
    }, []);
    return (
        <section className="container">
            <div ref={ourGoalRef} className={classes.ourGoal}>
                <div className={classes.image}>
                    <img src={img} alt=''/>
                </div>
                <div className={classes.content}>
                    <h2 className={[classes.title, 'font-42'].join(' ')}>
                        {t('our_goal_title')}
                    </h2>
                    <div className={[classes.text, 'font-20'].join(' ')}>
                        <ul>
                            <li>{t('our_goal_li_01')}</li>
                            <li>{t('our_goal_li_02')}</li>
                            <li>{t('our_goal_li_03')}</li>
                        </ul>
                        <p>
                            {t('our_goal_text_01')}
                        </p>
                        <p>
                            {t('our_goal_text_02')}
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default OurGoal;
