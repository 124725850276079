import React, { useEffect } from "react";
import Banner from "../../components/AccountsPage/Banner/Banner";
import LeadingBroker from "../../components/AccountsPage/LeadingBroker/LeadingBroker";
import OpenAccount from "../../components/AccountsPage/OpenAccount/OpenAccount";
import WhyChoose from "../../components/AccountsPage/WhyChoose/WhyChoose";
import Footer from "../../components/GlobalComponents/Footer/Footer";
import Header from "../../components/GlobalComponents/Header/Header";
import TradingPlans from "../../components/TradingPage/TradingPlans/TradingPlans";

const AccountsPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="ovf-hidden">
      <Header />
      <Banner />
      <TradingPlans />
      <OpenAccount />
      <WhyChoose />
      <LeadingBroker />
      <Footer />
    </div>
  );
};

export default AccountsPage;
