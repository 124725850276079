import React from "react";
import classes from "./First.module.scss";
import Button from "../../GlobalComponents/Button/Button";
import { Trans, useTranslation } from "react-i18next";

const First = () => {
  const { t } = useTranslation();
  return (
    <div className={[classes.first, ""].join(" ")}>
      <div className={[classes.firstBody, ""].join(" ")}>
        <div className="container">
          <div className={[classes.firstWrapper, ""].join(" ")}>
            <div className={[classes.firstTitle, "font-42"].join(" ")}>
              <Trans>
                {t('home_banner_title')}
              </Trans>
            </div>
            <div className={[classes.firstBtn, ""].join(" ")}>
              <Button>{t('btn_start')}</Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default First;
