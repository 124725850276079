import React, { useRef, useEffect } from "react";
import classes from "./Six.module.scss";
import { useTranslation } from "react-i18next";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const Six = () => {
  const { t } = useTranslation();
  const sixRef = useRef(null);
  useEffect(() => {
    if (document.documentElement.clientWidth > 1220) {
      gsap.timeline().fromTo(sixRef.current, {
        y: 100,
        opacity: 0,
      }, {
        y: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: sixRef.current,
          end: "+=200",
          scrub: 2,
        }
      });
    }
  }, []);
  return (
    <div className={[classes.six, "global-pb"].join(" ")}>
      <div ref={sixRef} className={[classes.sixBody, ""].join(" ")}>
        <div className="container">
          <div className={[classes.sixTitle, "font-42"].join(" ")}>
            {t('why_invest_title')}
          </div>
          <div className={[classes.sixRowBlack, ""].join(" ")}>
            <div className={[classes.sixRowBlackLeft, ""].join(" ")}>
              <div
                className={[classes.sixRowBlackLeftTitle, "font-42", classes.wowtitle1].join(" ")}
              >
                {t('why_invest_card_01_title')}
              </div>
              <div
                className={[classes.sixRowBlackLeftSubtitle, "font-20"].join(
                  " "
                )}
              >
                {t('why_invest_card_01_text')}
              </div>
            </div>
            <div className={[classes.sixRowBlackRight, ""].join(" ")}></div>
          </div>
          <div className={[classes.sixRowWhite, classes.blue, ""].join(" ")}>
            <div className={[classes.sixRowWhiteLeft, ""].join(" ")}></div>
            <div className={[classes.sixRowWhiteRight, ""].join(" ")}>
              <div
                className={[classes.sixRowBlackRightTitle, "font-42", classes.wowtitle2].join(" ")}
              >
                {t('why_invest_card_02_title')}
              </div>
              <div
                className={[classes.sixRowBlackRightSubtitle, "font-20", classes.wowsuitledark].join(
                  " "
                )}
              >
                {t('why_invest_card_02_text')}
              </div>
            </div>
          </div>
          <div className={[classes.sixRowBlack, classes.white, ""].join(" ")}>
            <div className={[classes.sixRowBlackLeft, ""].join(" ")}>
              <div
                className={[classes.sixRowBlackLeftTitle, "font-42", classes.wowtitle3].join(" ")}
              >
                {t('why_invest_card_03_title')}
              </div>
              <div
                className={[classes.sixRowBlackLeftSubtitle, "font-20"].join(
                  " "
                )}
              >
                {t('why_invest_card_03_text')}
              </div>
            </div>
            <div className={[classes.sixRowBlackRight, ""].join(" ")}></div>
          </div>
          <div className={[classes.sixRowWhite, ""].join(" ")}>
            <div className={[classes.sixRowWhiteLeft, ""].join(" ")}></div>
            <div className={[classes.sixRowWhiteRight, ""].join(" ")}>
              <div
                className={[classes.sixRowBlackRightTitle, "font-42", classes.wowtitle4].join(" ")}
              >
                {t('why_invest_card_04_title')}
              </div>
              <div
                className={[classes.sixRowBlackRightSubtitle, "font-20"].join(
                  " "
                )}
              >
                {t('why_invest_card_04_text')}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Six;
