import React, { useEffect, useRef } from "react";
import classes from "./Five.module.scss";
import { Trans, useTranslation } from "react-i18next";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const Five = () => {
  const { t } = useTranslation();
  const fiveRef = useRef(null);
  useEffect(() => {
    if (document.documentElement.clientWidth > 1220) {
      gsap.timeline().fromTo(fiveRef.current, {
        y: 100,
        opacity: 0,
      }, {
        y: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: fiveRef.current,
          end: "+=200",
          scrub: 2,
        }
      });
    }
  }, []);
  return (
    <div className={[classes.five, "global-pb"].join(" ")}>
      <div ref={fiveRef} className={[classes.fiveBody, ""].join(" ")}>
        <div className="container">
          <div className={[classes.fiveTitle, "font-42"].join(" ")}>
            {t('what_can_you_title')}
          </div>
          <div className={[classes.fiveSubtitle, "font-20"].join(" ")}>
            <Trans>
              {t('what_can_you_text')}
            </Trans>
          </div>
          <div className={[classes.fiveRow, ""].join(" ")}>
            <div className={[classes.fiveRowColumn, ""].join(" ")}>
              <div className={[classes.fiveRowColumnItem, ""].join(" ")}>
                <div
                  className={[classes.fiveRowColumnItemImg, ""].join(" ")}
                ></div>
                <div
                  className={[classes.fiveRowColumnItemTitle, "font-20"].join(
                    " "
                  )}
                >
                  {t('what_can_you_card_title_01')}
                </div>
                <div className={[classes.fiveRowColumnItemList, ""].join(" ")}>
                  <div
                    className={[
                      classes.fiveRowColumnItemListItem,
                      "font-18",
                    ].join(" ")}
                  >
                    EUR/USD
                  </div>
                  <div
                    className={[
                      classes.fiveRowColumnItemListItem,
                      "font-18",
                    ].join(" ")}
                  >
                    AUD/USD
                  </div>
                  <div
                    className={[
                      classes.fiveRowColumnItemListItem,
                      "font-18",
                    ].join(" ")}
                  >
                    GBP/USD
                  </div>
                  <div
                    className={[
                      classes.fiveRowColumnItemListItem,
                      "font-18",
                    ].join(" ")}
                  >
                    EUR/JPY
                  </div>
                  <div
                    className={[
                      classes.fiveRowColumnItemListItem,
                      "font-18",
                    ].join(" ")}
                  >
                    USD/JPY
                  </div>
                </div>
              </div>
            </div>
            <div className={[classes.fiveRowColumn, ""].join(" ")}>
              <div className={[classes.fiveRowColumnItem, ""].join(" ")}>
                <div
                  className={[classes.fiveRowColumnItemImg, ""].join(" ")}
                ></div>
                <div
                  className={[classes.fiveRowColumnItemTitle, "font-20"].join(
                    " "
                  )}
                >
                  {t('what_can_you_card_title_02')}
                </div>
                <div className={[classes.fiveRowColumnItemList, ""].join(" ")}>
                  <div
                    className={[
                      classes.fiveRowColumnItemListItem,
                      "font-18",
                    ].join(" ")}
                  >
                    Oro
                  </div>
                  <div
                    className={[
                      classes.fiveRowColumnItemListItem,
                      "font-18",
                    ].join(" ")}
                  >
                    Petroleum
                  </div>
                  <div
                    className={[
                      classes.fiveRowColumnItemListItem,
                      "font-18",
                    ].join(" ")}
                  >
                    Gas natural
                  </div>
                  <div
                    className={[
                      classes.fiveRowColumnItemListItem,
                      "font-18",
                    ].join(" ")}
                  >
                    Platinum
                  </div>
                  <div
                    className={[
                      classes.fiveRowColumnItemListItem,
                      "font-18",
                    ].join(" ")}
                  >
                    Copper
                  </div>
                </div>
              </div>
            </div>
            <div className={[classes.fiveRowColumn, ""].join(" ")}>
              <div className={[classes.fiveRowColumnItem, ""].join(" ")}>
                <div
                  className={[classes.fiveRowColumnItemImg, ""].join(" ")}
                ></div>
                <div
                  className={[classes.fiveRowColumnItemTitle, "font-20"].join(
                    " "
                  )}
                >
                  {t('what_can_you_card_title_03')}
                </div>
                <div className={[classes.fiveRowColumnItemList, ""].join(" ")}>
                  <div
                    className={[
                      classes.fiveRowColumnItemListItem,
                      "font-18",
                    ].join(" ")}
                  >
                    Tesla
                  </div>
                  <div
                    className={[
                      classes.fiveRowColumnItemListItem,
                      "font-18",
                    ].join(" ")}
                  >
                    Google
                  </div>
                  <div
                    className={[
                      classes.fiveRowColumnItemListItem,
                      "font-18",
                    ].join(" ")}
                  >
                    Nio
                  </div>
                  <div
                    className={[
                      classes.fiveRowColumnItemListItem,
                      "font-18",
                    ].join(" ")}
                  >
                    Canon
                  </div>
                  <div
                    className={[
                      classes.fiveRowColumnItemListItem,
                      "font-18",
                    ].join(" ")}
                  >
                    Facebook
                  </div>
                </div>
              </div>
            </div>
            <div className={[classes.fiveRowColumn, ""].join(" ")}>
              <div className={[classes.fiveRowColumnItem, ""].join(" ")}>
                <div
                  className={[classes.fiveRowColumnItemImg, ""].join(" ")}
                ></div>
                <div
                  className={[classes.fiveRowColumnItemTitle, "font-20"].join(
                    " "
                  )}
                >
                  {t('what_can_you_card_title_04')}
                </div>
                <div className={[classes.fiveRowColumnItemList, ""].join(" ")}>
                  <div
                    className={[
                      classes.fiveRowColumnItemListItem,
                      "font-18",
                    ].join(" ")}
                  >
                    Germany 30
                  </div>
                  <div
                    className={[
                      classes.fiveRowColumnItemListItem,
                      "font-18",
                    ].join(" ")}
                  >
                    India 50
                  </div>
                  <div
                    className={[
                      classes.fiveRowColumnItemListItem,
                      "font-18",
                    ].join(" ")}
                  >
                    US 30
                  </div>
                  <div
                    className={[
                      classes.fiveRowColumnItemListItem,
                      "font-18",
                    ].join(" ")}
                  >
                    US 100
                  </div>
                  <div
                    className={[
                      classes.fiveRowColumnItemListItem,
                      "font-18",
                    ].join(" ")}
                  >
                    Japan 225
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Five;
