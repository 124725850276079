import React from "react";
import classes from './Banner.module.scss';
import human from '../../../assets/img/AccountsPage/Banner/human.png';
import town from '../../../assets/img/AccountsPage/Banner/town.png';
import Button from "../../GlobalComponents/Button/Button";
import { useTranslation } from "react-i18next";

const Banner = () => {
    const { t } = useTranslation();
    return (
        <section className={classes.banner}>
            <div className="container">
                <div className={classes.bannerBody}>
                    <h1 className={[classes.title, 'font-42'].join(' ')}>
                        {t('accounts_banner_title')}
                    </h1>
                    <div className={classes.btn}>
                        <Button>{t('btn_start')}</Button>
                    </div>
                    <img className={classes.human} src={human} alt=''/>
                </div>
            </div>
            <img className={classes.town} src={town} alt=''/>
        </section>
    );
};

export default Banner;
