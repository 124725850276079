import React, { useEffect, useState, useRef } from "react";
import classes from './MainTerms.module.scss';
import { useTranslation } from "react-i18next";
import $ from 'jquery';
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const buttons = [
    {
        id: 1,
        name: 'main_terms_li_01_title',
    },
    {
        id: 2,
        name: 'main_terms_li_02_title',
    },
    {
        id: 3,
        name: 'main_terms_li_03_title',
    },
    {
        id: 4,
        name: 'main_terms_li_04_title',
    },
    {
        id: 5,
        name: 'main_terms_li_05_title',
    },
];
const content = {
    1: 'main_terms_li_01_text',
    2: 'main_terms_li_02_text',
    3: 'main_terms_li_03_text',
    4: 'main_terms_li_04_text',
    5: 'main_terms_li_05_text',
};

const MainTerms = () => {
    const { t } = useTranslation();
    const [ currentTab, setCurrentTab ] = useState(1);
    var initSize = function() {
        var start_element = $('.tabs-block li:first-of-type');
        var $label = $('.investAtTabs_label');
        var initWidth = start_element.css('width')	
        $label.css('width', initWidth);
    }
    useEffect(() => {
        initSize();
        $('.tabs-block li').on('click', function(){
            var $label = $('.investAtTabs_label');	
            var $this = $(this);
            var el_width = $this.width();
            var offset = $this.offset();
            $label
                .offset({ left: offset.left })
                .offset({ top: offset.top })
                .css('width', el_width)
        });
    }, []);
    const mainTermsRef = useRef(null);
    useEffect(() => {
        if (document.documentElement.clientWidth > 1220) {
            gsap.timeline().fromTo(mainTermsRef.current, {
                y: 100,
                opacity: 0,
            }, {
                y: 0,
                opacity: 1,
                scrollTrigger: {
                trigger: mainTermsRef.current,
                end: "+=200",
                scrub: 2,
                }
            });
        }
    }, []);
    return (
        <section className="container">
            <div ref={mainTermsRef} className={classes.howToCreate}>
                <h2 className={[classes.title, 'font-42'].join(' ')}>
                    {t('main_terms_title')}
                </h2>
                <div className={classes.content}>
                    <div className={[classes.text, 'font-20'].join(' ')}>
                        <p>
                            {t('main_terms_text_01')}
                        </p>
                        <p>
                            {t('main_terms_text_02')}
                        </p>
                    </div>
                    <div className={[classes.text, 'font-20'].join(' ')}>
                        <p>
                            {t('main_terms_text_03')}
                        </p>
                    </div>
                </div>
                <div className={classes.tabs}>
                    <ul className={`${classes.buttons} tabs-block`}>
                        <div className={`${classes.label} investAtTabs_label`}></div>
                        {buttons.map((btn) =>
                            <li
                                key={btn.id}
                                onClick={() => setCurrentTab(btn.id)}
                                className={`${classes.btn} ${currentTab === btn.id && classes.btnActive}`}
                            >
                                <span>
                                    {t(btn.name)}
                                </span>
                            </li>
                        )}
                    </ul>
                    <div className={classes.content}>
                        <p>
                            {t(content[currentTab])}
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default MainTerms;
