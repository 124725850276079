import React, { useEffect } from "react";
import Banner from "../../components/AboutPage/Banner/Banner";
import OurAdvantages from "../../components/AboutPage/OurAdvantages/OurAdvantages";
import OurGoal from "../../components/AboutPage/OurGoal/OurGoal";
import OurMission from "../../components/AboutPage/OurMission/OurMission";
import PlatformIsYour from "../../components/AboutPage/PlatformIsYour/PlatformIsYour";
import Footer from "../../components/GlobalComponents/Footer/Footer";
import Header from "../../components/GlobalComponents/Header/Header";

const AboutPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Header />
      <Banner />
      <PlatformIsYour />
      <OurMission />
      <OurAdvantages />
      <OurGoal />
      <Footer />
    </div>
  );
};

export default AboutPage;
