import React, { useEffect } from "react";
import classes from "./SupportPage.module.scss";
import Header from "../../components/GlobalComponents/Header/Header";
import Faq from "../../components/SupportComponents/Faq/Faq";
import Four from "../../components/SupportComponents/Four/Four";
import Footer from "../../components/GlobalComponents/Footer/Footer";
import Hours from "../../components/SupportComponents/Hours/Hours";
const SupportPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className={classes.support}>
      <div className={[classes.supportW, ""].join(" ")}>
        <Header />
        <Faq />
        <Four />
        <Hours />
        <Footer />
      </div>
    </div>
  );
};

export default SupportPage;
