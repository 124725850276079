import AboutPage from "../pages/AboutPage/AboutPage";
import AccountsPage from "../pages/AccountsPage/AccountsPage";
import EducationPage from "../pages/EducationPage/EducationPage";
import MainPage from "../pages/MainPage/MainPage";
import SupportPage from "../pages/SupportPage/SupportPage";
import TradingPage from "../pages/TradingPage/TradingPage";

export const routes = [
    { path: '/trading', element: TradingPage },
    { path: '/accounts', element: AccountsPage },
    { path: '/education', element: EducationPage },
    { path: '/support', element: SupportPage },
    { path: '/about', element: AboutPage },
    { path: '/', element: MainPage },

];

export const headerRoutes = [
    { id: 1, path: '/trading', text: 'header_trading' },
    { id: 2, path: '/accounts', text: 'header_accounts' },
    { id: 3, path: '/education', text: 'header_education' },
    { id: 4, path: '/support', text: 'header_support' },
    { id: 5, path: '/about', text: 'header_about' },
];
