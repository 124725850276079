import React from "react";
import classes from "./Banner.module.scss";
import Button from "../../GlobalComponents/Button/Button";
import { Trans, useTranslation } from "react-i18next";

const Banner = () => {
  const { t } = useTranslation();
  return (
    <section className={classes.banner}>
      <div className="container">
        <div className={classes.bannerBody}>
          <h1 className={classes.title}>
            {t('trading_banner_title')}
          </h1>
          <p className={classes.text}>
            <Trans>
              {t('trading_banner_text')}
            </Trans>
          </p>
          <div className={classes.btn}>
            <Button second="true">{t('btn_start')}</Button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner;
