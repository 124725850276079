import React, { useEffect, useRef } from "react";
import classes from './Table.module.scss';
import mark_true from '../../../assets/img/TradingPage/Table/mark_true.png';
import mark_false from '../../../assets/img/TradingPage/Table/mark_false.png';
import { Trans, useTranslation } from "react-i18next";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const rows = [
    [
        { text: 'trading_table_col_01_row_01' },
        {
            list: [
                'trading_table_col_02_li_01',
                'trading_table_col_02_li_02'
            ]
        },
        {
            list: [
                'trading_table_col_03_li_01',
                'trading_table_col_03_li_02'
            ]
        },
        {
            list: [
                'trading_table_col_04_li_01',
                'trading_table_col_04_li_02'
            ]
        },
    ],
    [
        { text: 'trading_table_col_01_row_02' },
        { text: '€250' },
        { text: '€1000' },
        { text: '€5000' },
    ],
    [
        { text: 'trading_table_col_01_row_03' },
        { text: 'from 0.8' },
        { text: 'from 0.8' },
        { text: 'from 0.1' },
    ],
    [
        { text: 'trading_table_col_01_row_04' },
        { text: 'Forex, Commodities, Indices,<br/>Cryptos, Stock' },
        { text: 'Forex, Commodities, Indices,<br/>Cryptos, Stock' },
        { text: 'Forex, Commodities, Indices,<br/>Cryptos, Stock' },
    ],
    [
        { text: 'trading_table_col_01_row_05' },
        { image: mark_false },
        { image: mark_false },
        { image: mark_true },
    ],
    [
        { text: 'trading_table_col_01_row_06' },
        { image: mark_true },
        { image: mark_true },
        { image: mark_true },
    ],
    [
        { text: 'trading_table_col_01_row_07' },
        { text: '50%' },
        { text: '30%' },
        { text: '20%' },
    ],
    [
        { text: 'trading_table_col_01_row_08' },
        { text: '1:200' },
        { text: '1:100' },
        { text: '1:30' },
    ],
    [
        { text: 'trading_table_col_01_row_09' },
        { image: mark_true },
        { image: mark_true },
        { image: mark_true },
    ],
    [
        { text: 'trading_table_col_01_row_11' },
        { text: '24/5 financial markets' },
        { text: '24/5' },
        { text: '24/5' },
    ],
    [
        { text: 'trading_table_col_01_row_12' },
        { text: 'USD, EUR, GBP' },
        { text: 'USD, EUR, GBP' },
        { text: 'USD, EUR, GBP' },
    ],
    [
        { text: 'trading_table_col_01_row_13' },
        { image: mark_false },
        { image: mark_false },
        { image: mark_true },
    ],
    [
        { text: 'trading_table_col_01_row_14' },
        { image: mark_false },
        { image: mark_true },
        { image: mark_true },
    ],
    [
        { text: 'trading_table_col_01_row_15' },
        { image: mark_false },
        { image: mark_true },
        { image: mark_true },
    ],
];

const Table = () => {
    const { t } = useTranslation();
    const tableRef = useRef(null);
    useEffect(() => {
        if (document.documentElement.clientWidth > 1220) {
            gsap.timeline().fromTo(tableRef.current, {
                y: 100,
                opacity: 0,
            }, {
                y: 0,
                opacity: 1,
                scrollTrigger: {
                    trigger: tableRef.current,
                    end: "+=200",
                    scrub: 2,
                }
            });
        }
    }, []);
    return (
        <section ref={tableRef} className={[classes.container, "container"].join(' ')}>
            <div className={classes.tableWr}>
                <table>
                    <thead>
                        <tr>
                            <td></td>
                            <td>{t('trading_plans_card_01_title')}</td>
                            <td>{t('trading_plans_card_02_title')}</td>
                            <td>{t('trading_plans_card_03_title')}</td>
                        </tr>
                    </thead>
                    <tbody>
                        {rows.map((row, rowId) =>
                            <tr key={rowId}>
                                {// eslint-disable-next-line
                                row.map((col, colId) => {
                                    if (col.text) {
                                        return (
                                            <td key={colId}>
                                                <Trans>
                                                    {t(col.text)}
                                                </Trans>
                                            </td>
                                        );
                                    }
                                    if (col.list) {
                                        return (
                                            <td key={colId}>
                                                <ul>
                                                    {col.list.map((listItem, listId) =>
                                                        <li key={listId}>
                                                            <Trans>
                                                                {listItem}
                                                            </Trans>
                                                        </li>
                                                    )}
                                                </ul>
                                            </td>
                                        )
                                    }
                                    if (col.image) {
                                        return (
                                            <td key={colId}>
                                                <img src={col.image} alt=''/>
                                            </td>
                                        )
                                    }
                                })}
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </section>
    );
};

export default Table;
