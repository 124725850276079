import React, { useEffect, useRef } from "react";
import classes from './WhyChoose.module.scss';
import { useTranslation } from "react-i18next";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const rows = [
    [
        'why_choose_card_01_title',
        'why_choose_card_01_text',
    ],
    [
        'why_choose_card_02_title',
        'why_choose_card_02_text',
    ],
    [
        'why_choose_card_03_title',
        'why_choose_card_03_text',
    ],
    [
        'why_choose_card_04_title',
        '4.9/5',
    ],
    [
        'why_choose_card_05_title',
        'iOS & Android',
    ],
    [
        'why_choose_card_06_title',
        'why_choose_card_06_text',
    ],
    [
        'why_choose_card_07_title',
        'why_choose_card_07_text',
    ],
];

const WhyChoose = () => {
    const { t } = useTranslation();
    const whyChooseRef = useRef(null);
    useEffect(() => {
        if (document.documentElement.clientWidth > 1220) {
            gsap.timeline().fromTo(whyChooseRef.current, {
                y: 100,
                opacity: 0,
            }, {
                y: 0,
                opacity: 1,
                scrollTrigger: {
                trigger: whyChooseRef.current,
                end: "+=200",
                scrub: 2,
                }
            });
        }
    }, []);
    return (
        <div ref={whyChooseRef} className={classes.whyChoose}>
            <div className='container'>
                <h2 className={[classes.title, 'font-42'].join(' ')}>
                    {t('why_choose_title')}
                </h2>
            </div>
            <div className={[classes.container, 'container'].join(' ')}>
                <div className={classes.tableWr}>
                    <table>
                        <tbody>
                            {rows.map((row, rowId) =>
                                <tr key={rowId}>
                                    <td>
                                        {t(row[0])}
                                    </td>
                                    <td>
                                        {t(row[1])}
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default WhyChoose;
