import React, { useEffect, useRef } from "react";
import classes from './Embrace.module.scss';
import human from '../../../assets/img/TradingPage/Embrace/human.png';
import Button from "../../GlobalComponents/Button/Button";
import { Trans, useTranslation } from "react-i18next";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const Embrace = () => {
    const { t } = useTranslation();
    const embraceRef = useRef(null);
    useEffect(() => {
        if (document.documentElement.clientWidth > 1220) {
            gsap.timeline().fromTo(embraceRef.current, {
                y: 100,
                opacity: 0,
            }, {
                y: 0,
                opacity: 1,
                scrollTrigger: {
                    trigger: embraceRef.current,
                    end: "+=200",
                    scrub: 2,
                }
            });
        }
    }, []);
    return (
        <section ref={embraceRef} className={classes.embrace}>
            <div className="container">
                <div className={classes.embraceBody}>
                    <h2 className={classes.title}>
                        <Trans>
                            {t('embrace_title')}
                        </Trans>
                    </h2>
                    <p className={classes.text}>
                        <Trans>
                            {t('embrace_text')}
                        </Trans>
                    </p>
                    <div className={classes.btn}>
                        <Button second="true">{t('btn_sign_up')}</Button>
                    </div>
                    <img className={classes.human} src={human} alt=''/>
                </div>
            </div>
        </section>
    );
};

export default Embrace;
