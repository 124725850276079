import React, { useRef, useState, useEffect } from "react";
import classes from './OpenAccount.module.scss';
import { useTranslation } from "react-i18next";
import $ from 'jquery';
import tab_arrow from '../../../assets/img/AccountsPage/WhyChoose/tab_arrow.svg';
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const OpenAccount = () => {
    const { t } = useTranslation();
    const tab_01_ref = useRef(null);
    const tab_02_ref = useRef(null);
    const tab_03_ref = useRef(null);
    const [ tab01Active, setTab01Active ] = useState(false);
    const [ tab02Active, setTab02Active ] = useState(false);
    const [ tab03Active, setTab03Active ] = useState(false);
    useEffect(() => {
        $(tab_01_ref.current).slideUp(0);
        $(tab_02_ref.current).slideUp(0);
        $(tab_03_ref.current).slideUp(0);
    }, []);
    const openTradingAccRef = useRef(null);
    useEffect(() => {
        if (document.documentElement.clientWidth > 1220) {
            gsap.timeline().fromTo(openTradingAccRef.current, {
                y: 100,
                opacity: 0,
            }, {
                y: 0,
                opacity: 1,
                scrollTrigger: {
                    trigger: openTradingAccRef.current,
                    end: "+=200",
                    scrub: 2,
                }
            });
        }
    }, []);
    return (
        <section className="container">
            <div ref={openTradingAccRef} className={classes.whyChoose}>
                <h2 className={[classes.title, 'font-42'].join(' ')}>
                    {t('open_account_title')}
                </h2>
                <div className={classes.tabs}>
                    <div 
                        className={classes.tab}
                        onClick={() => {
                            $(tab_01_ref.current).slideToggle(300);
                            setTab01Active(value => !value);
                        }}    
                    >
                        <div className={classes.tabTitle}>
                            <h3 className={[classes.tabTitleText, 'text-default'].join(' ')}>
                                {t('open_account_q_01_title')}
                            </h3>
                            <img className={`${classes.tabTitleStatus} ${tab01Active && classes.active}`} src={tab_arrow} alt=''/>
                        </div>
                        <div ref={tab_01_ref} className={classes.tabContent}>
                            <div className={classes.tabContentBody}>
                                <p>
                                    {t('open_account_q_01_text_01')}
                                </p>
                                <ul>
                                    <li>{t('open_account_q_01_li_01')}</li>
                                    <li>{t('open_account_q_01_li_02')}</li>
                                    <li>{t('open_account_q_01_li_03')}</li>
                                    <li>{t('open_account_q_01_li_04')}</li>
                                    <li>{t('open_account_q_01_li_05')}</li>
                                </ul>
                                <p>
                                    {t('open_account_q_01_text_01')}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div 
                        className={classes.tab}
                        onClick={() => {
                            $(tab_02_ref.current).slideToggle(300);
                            setTab02Active(value => !value);
                        }}    
                    >
                        <div className={classes.tabTitle}>
                            <h3 className={[classes.tabTitleText, 'text-default'].join(' ')}>
                                {t('open_account_q_02_title')}
                            </h3>
                            <img className={`${classes.tabTitleStatus} ${tab02Active && classes.active}`} src={tab_arrow} alt=''/>
                        </div>
                        <div ref={tab_02_ref} className={classes.tabContent}>
                            <div className={classes.tabContentBody}>
                                <ul>
                                    <li>{t('open_account_q_02_li_01')}</li>
                                    <li>{t('open_account_q_02_li_02')}</li>
                                    <li>{t('open_account_q_02_li_03')}</li>
                                </ul>
                                <p>
                                    {t('open_account_q_02_text')}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div 
                        className={classes.tab}
                        onClick={() => {
                            $(tab_03_ref.current).slideToggle(300);
                            setTab03Active(value => !value);
                        }}    
                    >
                        <div className={classes.tabTitle}>
                            <h3 className={[classes.tabTitleText, 'text-default'].join(' ')}>
                                {t('open_account_q_03_title')}
                            </h3>
                            <img className={`${classes.tabTitleStatus} ${tab03Active && classes.active}`} src={tab_arrow} alt=''/>
                        </div>
                        <div ref={tab_03_ref} className={classes.tabContent}>
                            <div className={classes.tabContentBody}>
                                <ul>
                                    <li>{t('open_account_q_03_li_01')}</li>
                                    <li>{t('open_account_q_03_li_02')}</li>
                                    <li>{t('open_account_q_03_li_03')}</li>
                                </ul>
                                <p>
                                    {t('open_account_q_03_text_01')}
                                </p>
                                <ul>
                                    <li>{t('open_account_q_03_li_04')}</li>
                                    <li>{t('open_account_q_03_li_05')}</li>
                                    <li>{t('open_account_q_03_li_06')}</li>
                                </ul>
                                <p>
                                    {t('open_account_q_03_text_02')}
                                </p>
                                <ol>
                                    <li>{t('open_account_q_03_li_07')}</li>
                                    <li>{t('open_account_q_03_li_08')}</li>
                                    <li>{t('open_account_q_03_li_09')}</li>
                                    <li>{t('open_account_q_03_li_10')}</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default OpenAccount;
