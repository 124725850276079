import React from "react";
import classes from "./Button.module.scss";
import Cookies from 'js-cookie';

const Button = (props) => {
  const lang = Cookies.get('i18next');
  if (props.second) {
    return (
      <a href={`https://dash.goldman-group.in/signup?lang=${lang}`} {...props} className={[classes.btn, classes.white].join(" ")}>
        <span>
          {props.children}
        </span>
      </a>
    );
  }

  return (
    <a href={`https://dash.goldman-group.in/signup?lang=${lang}`} {...props} className={classes.btn}>
      <span>
        {props.children}
      </span>
    </a>
  );
};

export default Button;
