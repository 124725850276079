import React, { useState, useRef, useEffect } from "react";
import classes from "./Four.module.scss";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import img1 from "../../../assets/img/main/4/1.png";
import { useTranslation } from "react-i18next";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const Four = () => {
  const [check1, setCheck1] = useState(false);
  const [check2, setCheck2] = useState(false);
  const { t } = useTranslation();
  const fourRef = useRef(null);
  useEffect(() => {
    if (document.documentElement.clientWidth > 1220) {
      gsap.timeline().fromTo(fourRef.current, {
        y: 100,
        opacity: 0,
      }, {
        y: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: fourRef.current,
          end: "+=200",
          scrub: 2,
        }
      });
    }
  }, []);
  return (
    <div className={[classes.four, "global-pb"].join(" ")}>
      <div ref={fourRef} className={[classes.fourBody, ""].join(" ")}>
        <div className="container">
          <div className={[classes.fourRow, ""].join(" ")}>
            <div className={[classes.fourRowLeft, ""].join(" ")}>
              <div className={[classes.fourRowLeftForm, ""].join(" ")}>
                <div
                  className={[classes.fourRowLeftFormTitle, "font-42"].join(
                    " "
                  )}
                >
                  {t('register_now_title')}
                </div>
                <div
                  className={[classes.fourRowLeftFormOverTitle, "font-20"].join(
                    " "
                  )}
                >
                  {t('register_now_subtitle')}
                </div>
                <div className={[classes.fourRowLeftFormList, ""].join(" ")}>
                  <div
                    className={[
                      classes.fourRowLeftFormListItem,
                      "font-20",
                    ].join(" ")}
                  >
                    {t('register_now_li_01')}
                  </div>
                  <div
                    className={[
                      classes.fourRowLeftFormListItem,
                      "font-20",
                    ].join(" ")}
                  >
                    {t('register_now_li_02')}
                  </div>
                  <div
                    className={[
                      classes.fourRowLeftFormListItem,
                      "font-20",
                    ].join(" ")}
                  >
                    {t('register_now_li_03')}
                  </div>
                </div>
                <div className={[classes.fourRowLeftName, ""].join(" ")}>
                  <input type="text" placeholder={t('register_now_name')} />
                </div>
                <div className={[classes.fourRowLeftEmail, ""].join(" ")}>
                  <input type="text" placeholder="Email" />
                </div>
                <div className={[classes.fourRowLeftPhone, ""].join(" ")}>
                  <PhoneInput
                    placeholder="Phone"
                    country={"us"}
                  />
                </div>
                <div className={[classes.fourRowLeftSign, ""].join(" ")}>
                  {t('btn_sign_up')}
                </div>
                <div
                  className={
                    check1
                      ? [classes.fourRowLeftPolicy, classes.active, ""].join(
                          " "
                        )
                      : [classes.fourRowLeftPolicy, ""].join(" ")
                  }
                  onClick={() => {
                    setCheck1(!check1);
                  }}
                >
                  {t('register_now_check_01')}
                </div>
                <div
                  className={
                    check2
                      ? [classes.fourRowLeftCom, classes.active, ""].join(" ")
                      : [classes.fourRowLeftCom, ""].join(" ")
                  }
                  onClick={() => {
                    setCheck2(!check2);
                  }}
                >
                  {t('register_now_check_02')}
                </div>
              </div>
            </div>
            <div className={[classes.fourRowRight, ""].join(" ")}>
              <img src={img1} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Four;
