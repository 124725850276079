import React, { useRef, useEffect } from "react";
import classes from "./Four.module.scss";
import "react-phone-input-2/lib/style.css";
import img1 from "../../../assets/img/main/4/1.png";
import { useTranslation } from "react-i18next";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const Four = () => {
  const { t } = useTranslation();
  const stillHaveRef = useRef(null);
  useEffect(() => {
    if (document.documentElement.clientWidth > 1220) {
      gsap.timeline().fromTo(stillHaveRef.current, {
        y: 100,
        opacity: 0,
      }, {
        y: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: stillHaveRef.current,
          end: "+=200",
          scrub: 2,
        }
      });
    }
  }, []);
  return (
    <div className={[classes.four, "global-pb"].join(" ")}>
      <div ref={stillHaveRef} className={[classes.fourBody, ""].join(" ")}>
        <div className="container">
          <div className={[classes.fourRow, ""].join(" ")}>
            <div className={[classes.fourRowLeft, ""].join(" ")}>
              <div className={[classes.fourRowLeftForm, ""].join(" ")}>
                <div
                  className={[classes.fourRowLeftFormTitle, "font-42"].join(
                    " "
                  )}
                >
                  {t('still_have_title')}
                </div>
                <div
                  className={[classes.fourRowLeftFormOverTitle, "font-20"].join(
                    " "
                  )}
                >
                  {t('still_have_subtitle')}
                </div>
                <div className={[classes.fourRowLeftFormList, ""].join(" ")}>
                  <div
                    className={[
                      classes.fourRowLeftFormListItem,
                      "font-20",
                    ].join(" ")}
                  >
                    {t('still_have_text')}
                  </div>
                </div>
                <div className={[classes.fourRowLeftName, ""].join(" ")}>
                  <input type="text" placeholder={t('still_have_name')} />
                </div>
                <div className={[classes.fourRowLeftName, ""].join(" ")}>
                  <input type="text" placeholder={t('still_have_last_name')} />
                </div>
                <div className={[classes.fourRowLeftEmail, ""].join(" ")}>
                  <input type="text" placeholder="Email" />
                </div>
                <div className={[classes.fourRowLeftTextarea, ""].join(" ")}>
                  <textarea placeholder={t('still_have_request')}></textarea>
                </div>
                <div className={[classes.fourRowLeftSign, ""].join(" ")}>
                  {t('still_have_contact_us')}
                </div>
              </div>
            </div>
            <div className={[classes.fourRowRight, ""].join(" ")}>
              <img src={img1} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Four;
