import React, { useEffect } from "react";
import Footer from "../../components/GlobalComponents/Footer/Footer";
import Header from "../../components/GlobalComponents/Header/Header";
import Banner from "../../components/TradingPage/Banner/Banner";
import DiscoverNew from "../../components/TradingPage/DiscoverNew/DiscoverNew";
import Embrace from "../../components/TradingPage/Embrace/Embrace";
import Faq from "../../components/TradingPage/Faq/Faq";
import Markets from "../../components/TradingPage/Markets/Markets";
import StartTrading from "../../components/TradingPage/StartTrading/StartTrading";
import Table from "../../components/TradingPage/Table/Table";
import TradingPlans from "../../components/TradingPage/TradingPlans/TradingPlans";

const TradingPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="ovf-hidden">
      <Header />
      <Banner />
      <TradingPlans />
      <DiscoverNew />
      <Table />
      <Markets />
      <Embrace />
      <StartTrading />
      <Faq />
      <Footer />
    </div>
  );
};

export default TradingPage;
