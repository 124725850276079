import logo from '../assets/img/header/logo.png';
// main
import main_banner from '../assets/img/main/1/1.png';
import main_underbanner_01 from '../assets/img/main/2/1.png';
import main_underbanner_02 from '../assets/img/main/2/2.png';
import main_underbanner_03 from '../assets/img/main/2/3.png';
import what_makes_01 from '../assets/img/main/3/1.png';
import what_makes_02 from '../assets/img/main/3/2.png';
import what_makes_03 from '../assets/img/main/3/3.png';
import what_makes_04 from '../assets/img/main/3/4.png';
import what_makes_05 from '../assets/img/main/3/5.png';
import what_makes_06 from '../assets/img/main/3/6.png';
// trading
import trading_banner_bg from '../assets/img/TradingPage/Banner/bg.png';
// import trading_banner_bg_mob from '../assets/img/TradingPage/Banner/bg_mob.png';
// import trading_plans_bg from '../assets/img/TradingPage/TradingPlans/bg.png';
import trading_plans_card_img_01 from '../assets/img/TradingPage/TradingPlans/card_img_01.png';
import trading_plans_card_img_02 from '../assets/img/TradingPage/TradingPlans/card_img_02.png';
import trading_plans_card_img_03 from '../assets/img/TradingPage/TradingPlans/card_img_03.png';
// accounts
import acc_banner_01 from '../assets/img/AccountsPage/Banner/human.png';
import acc_banner_02 from '../assets/img/AccountsPage/Banner/town.png';
// education
import edu_banner_01 from '../assets/img/EducationPage/Banner/bg.png';
import edu_banner_02 from '../assets/img/EducationPage/Banner/mac.png';
// about
import about_banner_img from '../assets/img/AboutPage/Banner/img.png';

export const images = [
    logo,
    main_banner,
    main_underbanner_01,
    main_underbanner_02,
    main_underbanner_03,
    what_makes_01,
    what_makes_02,
    what_makes_03,
    what_makes_04,
    what_makes_05,
    what_makes_06,
    trading_banner_bg,
    trading_plans_card_img_01,
    trading_plans_card_img_02,
    trading_plans_card_img_03,
    acc_banner_01,
    acc_banner_02,
    edu_banner_01,
    edu_banner_02,
    about_banner_img,
];
