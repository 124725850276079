import React, { useState, useEffect, useRef } from "react";
import classes from './OurAdvantages.module.scss';
import img from '../../../assets/img/AboutPage/OurAdvantages/img.png';
import { useTranslation } from "react-i18next";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const tabs = [
    {
        id: 1,
        title: 'our_advantages_card_01_title',
        text: 'our_advantages_card_01_text',
    },
    {
        id: 2,
        title: 'our_advantages_card_02_title',
        text: 'our_advantages_card_02_text',
    },
    {
        id: 3,
        title: 'our_advantages_card_03_title',
        text: 'our_advantages_card_03_text',
    },
    {
        id: 4,
        title: 'our_advantages_card_04_title',
        text: 'our_advantages_card_04_text',
    },
    {
        id: 5,
        title: 'our_advantages_card_05_title',
        text: 'our_advantages_card_05_text',
    },
    {
        id: 6,
        title: 'our_advantages_card_06_title',
        text: 'our_advantages_card_06_text',
    },
];

const OurAdvantages = () => {
    const { t } = useTranslation();
    const [ currentTab, setCurrentTab ] = useState(1);
    const ourAdvRef = useRef(null);
    useEffect(() => {
        if (document.documentElement.clientWidth > 1220) {
            gsap.timeline().fromTo(ourAdvRef.current, {
                y: 100,
                opacity: 0,
            }, {
                y: 0,
                opacity: 1,
                scrollTrigger: {
                trigger: ourAdvRef.current,
                end: "+=200",
                scrub: 2,
                }
            });
        }
    }, []);
    return (
        <section className="container">
            <div ref={ourAdvRef} className={classes.ourAdvantages}>
                <h2 className={[classes.title, 'font-42'].join(' ')}>
                    {t('our_advantages_title')}
                </h2>
                <p className={[classes.text, 'font-20'].join(' ')}>
                    {t('our_advantages_text')}
                </p>
                <div className={classes.content}>
                    <div className={classes.tabs}>
                        {tabs.map((tab) =>
                            <div 
                                key={tab.id} 
                                className={`${classes.tab} ${tab.id === currentTab && classes.current}`}
                                onClick={() => setCurrentTab(tab.id)}    
                            >
                                <h3 className={[classes.tabTitle, 'font-20'].join(' ')}>
                                    {t(tab.title)}
                                </h3>
                                <p className={`${classes.tabText} font-18`}>
                                    {t(tab.text)}
                                </p>
                            </div>
                        )}
                    </div>
                    <img className={classes.image} src={img} alt=''/>
                </div>
            </div>
        </section>
    );
};

export default OurAdvantages;
