import React from "react";
import classes from './PlatformIsYour.module.scss';
import { useTranslation } from "react-i18next";

const PlatformIsYour = () => {
    const { t } = useTranslation();
    return (
        <section className="container">
            <div className={classes.howToCreate}>
                <h2 className={[classes.title, 'font-42'].join(' ')}>
                    {t('platform_is_your_title')}
                </h2>
                <div className={classes.content}>
                    <div className={[classes.text, 'font-20'].join(' ')}>
                        <p>
                            {t('platform_is_your_text_01')}
                        </p>
                        <p>
                            {t('platform_is_your_text_02')}
                        </p>
                        <ul>
                            <li>{t('platform_is_your_li_01')}</li>
                            <li>{t('platform_is_your_li_02')}</li>
                            <li>{t('platform_is_your_li_03')}</li>
                            <li>{t('platform_is_your_li_04')}</li>
                        </ul>
                    </div>
                    <div className={[classes.text, 'font-20'].join(' ')}>
                        <p>
                            {t('platform_is_your_text_03')}
                        </p>
                        <p>
                            {t('platform_is_your_text_04')}
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default PlatformIsYour;
