import React, { useEffect, useRef } from "react";
import Button from "../../GlobalComponents/Button/Button";
import classes from './StartTrading.module.scss';
import { useTranslation } from "react-i18next";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const StartTrading = () => {
    const { t } = useTranslation();
    const startTradingRef = useRef(null);
    useEffect(() => {
        if (document.documentElement.clientWidth > 1220) {
            gsap.timeline().fromTo(startTradingRef.current, {
                y: 100,
                opacity: 0,
            }, {
                y: 0,
                opacity: 1,
                scrollTrigger: {
                    trigger: startTradingRef.current,
                    end: "+=200",
                    scrub: 2,
                }
            });
        }
    }, []);
    return (
        <section className="container">
            <div ref={startTradingRef} className={classes.startTrading}>
                <h2 className={[classes.title, 'font-42'].join(' ')}>
                    {t('start_trading_title')}
                </h2>
                <div className={classes.steps}>
                    <div className={[classes.step, classes.step_01].join(' ')}>
                        <div className={classes.stepNum}>
                            <span>1</span>
                        </div>
                        <div className={classes.stepContent}>
                            <h3 className={[classes.stepTitle, "font-20"].join(' ')}>
                                {t('start_trading_step_01_title')}
                            </h3>
                            <p className={[classes.stepText, "font-20"].join(' ')}>
                                {t('start_trading_step_01_text')}
                            </p>
                        </div>
                    </div>
                    <div className={[classes.step, classes.step_02].join(' ')}>
                        <div className={classes.stepNum}>
                            <span>2</span>
                        </div>
                        <div className={classes.stepContent}>
                            <h3 className={[classes.stepTitle, "font-20"].join(' ')}>
                                {t('start_trading_step_02_title')}
                            </h3>
                            <p className={[classes.stepText, "font-20"].join(' ')}>
                                {t('start_trading_step_02_text')}
                            </p>
                        </div>
                    </div>
                    <div className={[classes.step, classes.step_03].join(' ')}>
                        <div className={classes.stepNum}>
                            <span>3</span>
                        </div>
                        <div className={classes.stepContent}>
                            <h3 className={[classes.stepTitle, "font-20"].join(' ')}>
                                {t('start_trading_step_03_title')}
                            </h3>
                            <p className={[classes.stepText, "font-20"].join(' ')}>
                                {t('start_trading_step_03_text')}
                            </p>
                        </div>
                    </div>
                </div>
                <div className={classes.btn}>
                    <Button>{t('btn_trade_now')}</Button>
                </div>
            </div>
        </section>
    );
};

export default StartTrading;
