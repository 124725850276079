import React from "react";
import Button from "../../GlobalComponents/Button/Button";
import classes from './TradingPlans.module.scss';
import card_img_01 from '../../../assets/img/TradingPage/TradingPlans/card_img_01.png';
import card_img_02 from '../../../assets/img/TradingPage/TradingPlans/card_img_02.png';
import card_img_03 from '../../../assets/img/TradingPage/TradingPlans/card_img_03.png';
import card_img_04 from '../../../assets/img/TradingPage/TradingPlans/card_img_04.png';
import card_img_05 from '../../../assets/img/TradingPage/TradingPlans/card_img_05.png';
import dots from '../../../assets/img/TradingPage/TradingPlans/dots.gif';
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import './Swiper.scss';
import { useTranslation } from "react-i18next";

const cards = [
    {
        image: card_img_04,
        title: 'Bronze',
        subtitle: '€250',
        list: [
            'trading_plans_card_01_li_01',
            'trading_plans_card_01_li_02',
            'trading_plans_card_01_li_03',
            'trading_plans_card_01_li_04',
        ],
    },
    {
        image: card_img_02,
        title: 'Silver',
        subtitle: '€1.000',
        list: [
            'trading_plans_card_01_li_01',
            'trading_plans_card_02_li_02',
            'trading_plans_card_02_li_03',
            'trading_plans_card_02_li_04',
        ],
    },
    {
        image: card_img_05,
        title: 'Gold',
        subtitle: '€5.000',
        list: [
            'trading_plans_card_01_li_01',
            'trading_plans_card_03_li_02',
            'trading_plans_card_03_li_03',
            'trading_plans_card_03_li_04',
        ],
        hidden: [
            'trading_plans_card_03_li_05',
        ],
    },
    {
        image: card_img_01,
        title: 'Platinum',
        subtitle: '€25.000',
        list: [
            'trading_plans_card_01_li_01',
            'trading_plans_card_04_li_02',
            'trading_plans_card_04_li_03',
            'trading_plans_card_04_li_04',
        ],
        hidden: [
            'trading_plans_card_04_li_05',
            'trading_plans_card_04_li_06',
            'trading_plans_card_04_li_07',
        ],
    },
    {
        image: card_img_03,
        title: 'Black',
        subtitle: '€50.000',
        list: [
            'trading_plans_card_01_li_01',
            'trading_plans_card_05_li_02',
            'trading_plans_card_05_li_03',
            'trading_plans_card_05_li_04',
        ],
        hidden: [
            'trading_plans_card_05_li_05',
            'trading_plans_card_05_li_06',
            'trading_plans_card_05_li_07',
            'trading_plans_card_05_li_08',
            'trading_plans_card_05_li_09',
        ],
    },
];

const TradingPlans = () => {
    const { t } = useTranslation();
    return (
        <div className={classes.tradingPlansWr}>
            <section className={["container", classes.container].join(' ')}>
                <div className={classes.tradingPlans}>
                    <h2 className={classes.title}>
                        {t('trading_plans_title')}
                    </h2>
                    <div className={classes.cards}>
                        {cards.map((card, id) =>
                            <div key={id} className={classes.card}>
                                <img className={classes.cardImage} src={card.image} alt={card.title}/>
                                <h3 className={classes.cardTitle}>
                                    {t(card.title)}
                                </h3>
                                <h4 className={classes.cardSubtitle}>
                                    {t('trading_plans_cards_min_dep')} {card.subtitle}
                                </h4>
                                <ul>
                                    {card.list.map((li, index) =>
                                        <li key={index}>
                                            {t(li)}
                                        </li>
                                    )}
                                </ul>
                                {card.hidden &&
                                    <>
                                        <div className={classes.hidden}>
                                            <ul>
                                                {card.hidden?.map((li, index) =>
                                                    <li key={index}>
                                                        {t(li)}
                                                    </li>
                                                )}
                                            </ul>
                                        </div>
                                        <img className={classes.dots} src={dots} alt=''/>
                                    </>
                                }
                            </div>
                        )}
                    </div>
                    <div className={classes.swiperWr}>
                        <Swiper 
                            modules={[Pagination]}
                            className="tradingPlansSwiper"
                            spaceBetween={60}
                            slidesPerView={"auto"}
                            pagination={{clickable: true}}
                        >
                            {cards.map((card, id) =>
                                <SwiperSlide key={id} className="tradingPlansSwiperSlide">
                                    <div key={id} className={classes.card}>
                                        <img className={classes.cardImage} src={card.image} alt={card.title}/>
                                        <h3 className={classes.cardTitle}>
                                            {t(card.title)}
                                        </h3>
                                        <h4 className={classes.cardSubtitle}>
                                            {t('trading_plans_cards_min_dep')} {card.subtitle}
                                        </h4>
                                        <ul>
                                            {card.list.map((li, index) =>
                                                <li key={index}>
                                                    {t(li)}
                                                </li>
                                            )}
                                        </ul>
                                        {card.hidden &&
                                            <ul className={classes.swiperHidden}>
                                                {card.hidden.map((li, index) =>
                                                    <li key={index}>
                                                        {t(li)}
                                                    </li>
                                                )}
                                            </ul>
                                        }
                                    </div>
                                </SwiperSlide>
                            )}
                        </Swiper>
                    </div>
                    <div className={classes.btn}>
                        <Button>{t('btn_start_trading')}</Button>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default TradingPlans;
