import React, { useEffect, useRef } from "react";
import classes from './LeadingBroker.module.scss';
import image_01 from '../../../assets/img/AccountsPage/LeadingBroker/image_01.png';
import image_02 from '../../../assets/img/AccountsPage/LeadingBroker/image_02.png';
import image_03 from '../../../assets/img/AccountsPage/LeadingBroker/image_03.png';
import { useTranslation } from "react-i18next";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const LeadingBroker = () => {
    const { t } = useTranslation();
    const leadingBrokerRef_01 = useRef(null);
    const leadingBrokerRef_02 = useRef(null);
    const leadingBrokerRef_03 = useRef(null);
    useEffect(() => {
        if (document.documentElement.clientWidth > 1220) {
            gsap.timeline().fromTo(leadingBrokerRef_01.current, {
                y: 100,
                opacity: 0,
            }, {
                y: 0,
                opacity: 1,
                scrollTrigger: {
                    trigger: leadingBrokerRef_01.current,
                    end: "+=200",
                    scrub: 2,
                }
            });
            gsap.timeline().fromTo(leadingBrokerRef_02.current, {
                y: 100,
                opacity: 0,
            }, {
                y: 0,
                opacity: 1,
                scrollTrigger: {
                    trigger: leadingBrokerRef_02.current,
                    end: "+=200",
                    scrub: 2,
                }
            });
            gsap.timeline().fromTo(leadingBrokerRef_03.current, {
                y: 100,
                opacity: 0,
            }, {
                y: 0,
                opacity: 1,
                scrollTrigger: {
                    trigger: leadingBrokerRef_03.current,
                    end: "+=200",
                    scrub: 2,
                }
            });
        }
    }, []);
    return (
        <section className="container">
            <div className={classes.leadingBroker}>
                <div ref={leadingBrokerRef_01} className={classes.block}>
                    <div className={classes.content}>
                        <h2 className={[classes.title, 'font-42'].join(' ')}>
                            {t('leading_broker_title')}
                        </h2>
                        <div className={classes.text}>
                            <p>
                                {t('leading_broker_text_01')}
                            </p>
                            <p>
                                {t('leading_broker_text_02')}
                            </p>
                        </div>
                        <h3 className={classes.subtitle}>
                            {t('leading_broker_subtitle_01')}
                        </h3>
                        <div className={classes.text}>
                            <p>
                                {t('leading_broker_text_03')}
                            </p>
                        </div>
                        <h3 className={classes.subtitle}>
                            {t('leading_broker_subtitle_02')}
                        </h3>
                        <div className={classes.text}>
                            <p>
                                {t('leading_broker_text_04')}
                            </p>
                        </div>
                    </div>
                    <div className={classes.image}>
                        <img className={classes.image_01} src={image_01} alt=''/>
                    </div>
                </div>

                <div ref={leadingBrokerRef_02} className={classes.block}>
                    <div className={classes.image}>
                        <img className={classes.image_02} src={image_02} alt=''/>
                    </div>
                    <div className={classes.content}>
                        <h3 className={classes.subtitle}>
                            {t('leading_broker_subtitle_03')}
                        </h3>
                        <div className={classes.text}>
                            <p>
                                {t('leading_broker_text_05')}
                            </p>
                            <p>
                                {t('leading_broker_text_06')}
                            </p>
                            <p>
                                {t('leading_broker_text_07')}
                            </p>
                        </div>
                        <h3 className={classes.subtitle}>
                            {t('leading_broker_subtitle_04')}
                        </h3>
                        <div className={classes.text}>
                            <p>
                                {t('leading_broker_text_08')}
                            </p>
                        </div>
                    </div>
                </div>

                <div ref={leadingBrokerRef_03} className={classes.block}>
                    <div className={classes.content}>
                        <h3 className={classes.subtitle}>
                            {t('leading_broker_subtitle_05')}
                        </h3>
                        <div className={classes.text}>
                            <p>
                                {t('leading_broker_text_09')}
                            </p>
                        </div>
                        <h3 className={classes.subtitle}>
                            {t('leading_broker_subtitle_06')}
                        </h3>
                        <div className={classes.text}>
                            <p>
                                {t('leading_broker_text_10')}
                            </p>
                            <p>
                                {t('leading_broker_text_11')}
                            </p>
                        </div>
                    </div>
                    <div className={classes.image}>
                        <img className={classes.image_03} src={image_03} alt=''/>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default LeadingBroker;
