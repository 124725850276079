import React, { useEffect, useRef } from "react";
import Button from "../../GlobalComponents/Button/Button";
import classes from "./Seven.module.scss";
import { Trans, useTranslation } from "react-i18next";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const Seven = () => {
  const { t } = useTranslation();
  const sevenRef = useRef(null);
  useEffect(() => {
    if (document.documentElement.clientWidth > 1220) {
      gsap.timeline().fromTo(sevenRef.current, {
        y: 100,
        opacity: 0,
      }, {
        y: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: sevenRef.current,
          end: "+=200",
          scrub: 2,
        }
      });
    }
  }, []);
  return (
    <div className={[classes.seven, "global-pb"].join(" ")}>
      <div ref={sevenRef} className={[classes.sevenBody, ""].join(" ")}>
        <div className="container">
          <div className={[classes.sevenRow, ""].join(" ")}>
            <div className={[classes.sevenRowColumn, ""].join(" ")}>
              <div className={[classes.sevenRowColumnNumber, ""].join(" ")}>
                10
              </div>
              <div className={[classes.sevenRowColumnInfo, ""].join(" ")}>
                <div
                  className={[classes.sevenRowColumnInfoTitle, ""].join(" ")}
                >
                  {t('why_invest_bottom_card_more_than')}
                </div>
                <div
                  className={[
                    classes.sevenRowColumnInfoSubtitle,
                    "font-20",
                  ].join(" ")}
                >
                  <Trans>
                    {t('why_invest_bottom_card_01_text')}
                  </Trans>
                </div>
              </div>
            </div>
            <div className={[classes.sevenRowColumn, ""].join(" ")}>
              <div className={[classes.sevenRowColumnNumber, ""].join(" ")}>
                750
              </div>
              <div className={[classes.sevenRowColumnInfo, ""].join(" ")}>
                <div
                  className={[classes.sevenRowColumnInfoTitle, ""].join(" ")}
                >
                  {t('why_invest_bottom_card_more_than')}
                </div>
                <div
                  className={[
                    classes.sevenRowColumnInfoSubtitle,
                    "font-20",
                  ].join(" ")}
                >
                  <Trans>
                    {t('why_invest_bottom_card_02_text')}
                  </Trans>
                </div>
              </div>
            </div>
            <div className={[classes.sevenRowColumn, ""].join(" ")}>
              <div className={[classes.sevenRowColumnNumber, ""].join(" ")}>
                50.000
              </div>
              <div className={[classes.sevenRowColumnInfo, ""].join(" ")}>
                <div
                  className={[classes.sevenRowColumnInfoTitle, ""].join(" ")}
                >
                  {t('why_invest_bottom_card_more_than')}
                </div>
                <div
                  className={[
                    classes.sevenRowColumnInfoSubtitle,
                    "font-20",
                  ].join(" ")}
                >
                  <Trans>
                    {t('why_invest_bottom_card_03_text')}
                  </Trans>
                </div>
              </div>
            </div>
          </div>
          <div className={[classes.sevenBtn, ""].join(" ")}>
            <Button>{t('btn_sign_up')}</Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Seven;
