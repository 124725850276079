import React from "react";
import classes from "./Second.module.scss";
import img1 from "../../../assets/img/main/2/1.png";
import img2 from "../../../assets/img/main/2/2.png";
import img3 from "../../../assets/img/main/2/3.png";
import { useTranslation } from "react-i18next";

const Second = () => {
  const { t } = useTranslation();
  return (
    <div className={[classes.second, "global-pb"].join(" ")}>
      <div className={[classes.secondBody, ""].join(" ")}>
        <div className="container">
          <div className={[classes.rowWrapper, ""].join(" ")}>
            <div className={[classes.row, ""].join(" ")}>
              <div className={[classes.rowColumn, ""].join(" ")}>
                <div className={[classes.rowColumnImg, ""].join(" ")}>
                  <img src={img1} alt="" />
                </div>
                <div className={[classes.rowColumnText, "font-20"].join(" ")}>
                  {t('home_banner_li_01')}
                </div>
              </div>
              <div className={[classes.rowColumn, ""].join(" ")}>
                <div className={[classes.rowColumnImg, ""].join(" ")}>
                  <img src={img2} alt="" />
                </div>
                <div className={[classes.rowColumnText, "font-20"].join(" ")}>
                  {t('home_banner_li_02')}
                </div>
              </div>
              <div className={[classes.rowColumn, ""].join(" ")}>
                <div className={[classes.rowColumnImg, ""].join(" ")}>
                  <img src={img3} alt="" />
                </div>
                <div className={[classes.rowColumnText, "font-20"].join(" ")}>
                  {t('home_banner_li_03')}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Second;
