import React, { useEffect, useRef } from "react";
import classes from './TopTrading.module.scss';
import { useTranslation } from "react-i18next";
import cubes from '../../../assets/img/EducationPage/TopTrading/cubes.png';
import Button from "../../GlobalComponents/Button/Button";
import bg from '../../../assets/img/EducationPage/TopTrading/bg.png';
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const TopTrading = () => {
    const { t } = useTranslation();
    const dayTradingRef = useRef(null);
    useEffect(() => {
        if (document.documentElement.clientWidth > 1220) {
        gsap.timeline().fromTo(dayTradingRef.current, {
            y: 100,
            opacity: 0,
        }, {
            y: 0,
            opacity: 1,
            scrollTrigger: {
            trigger: dayTradingRef.current,
            end: "+=200",
            scrub: 2,
            }
        });
        }
    }, []);
    return (
        <section className="container">
            <div className={classes.topTrading}>
                <div className={classes.blockbg}>
                    <h2 className={[classes.title, 'font-42'].join(' ')}>
                        {t('top_trading_title')}
                    </h2>
                    <p className={[classes.text, 'font-20'].join(' ')}>
                        {t('top_trading_text_01')}
                    </p>
                    <img className={classes.bg} src={bg} alt=''/>
                </div>
                <div ref={dayTradingRef} className={classes.block}>
                    <div className={classes.image}>
                        <img src={cubes} alt=''/>
                    </div>
                    <div className={classes.content}>
                        <h2 className={[classes.title, 'font-42'].join(' ')}>
                            {t('top_trading_subtitle')}
                        </h2>
                        <p className="font-20">
                            {t('top_trading_text_02')}
                        </p>
                        <ul className="font-20">
                            <li>{t('top_trading_li_01')}</li>
                            <li>{t('top_trading_li_02')}</li>
                            <li>{t('top_trading_li_03')}</li>
                        </ul>
                        <div className={classes.btn}>
                            <Button>{t('btn_start_trading')}</Button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default TopTrading;
