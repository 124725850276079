import React from "react";
import Button from "../../GlobalComponents/Button/Button";
import classes from './HowToCreate.module.scss';
import { useTranslation } from "react-i18next";

const HowToCreate = () => {
    const { t } = useTranslation();
    return (
        <section className="container">
            <div className={classes.howToCreate}>
                <h2 className={[classes.title, 'font-42'].join(' ')}>
                    {t('how_to_create_title')}
                </h2>
                <div className={classes.content}>
                    <div className={[classes.text, 'font-20'].join(' ')}>
                        <p>
                            {t('how_to_create_text_01')}
                        </p>
                        <p>
                            {t('how_to_create_text_02')}
                        </p>
                        <p>
                            {t('how_to_create_text_03')}
                        </p>
                        <p>
                            {t('how_to_create_text_04')}
                        </p>
                    </div>
                    <div className={[classes.text, 'font-20'].join(' ')}>
                        <p>
                            {t('how_to_create_text_05')}
                        </p>
                        <p>
                            {t('how_to_create_text_06')}
                        </p>
                        <p>
                            {t('how_to_create_text_07')}
                        </p>
                        <p>
                            {t('how_to_create_text_08')}
                        </p>
                    </div>
                </div>
                <div className={classes.btn}>
                    <Button>{t('btn_trade_now')}</Button>
                </div>
            </div>
        </section>
    );
};

export default HowToCreate;
