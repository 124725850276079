import React, { useRef, useEffect, useState } from "react";
import classes from "./Faq.module.scss";
import { useTranslation } from "react-i18next";
import $ from "jquery";

const Faq = () => {
  const { t } = useTranslation();
  const tab_01_ref = useRef(null);
  const tab_02_ref = useRef(null);
  const tab_03_ref = useRef(null);
  const tab_04_ref = useRef(null);
  const tab_05_ref = useRef(null);
  const tab_06_ref = useRef(null);
  const tab_07_ref = useRef(null);
  const tab_08_ref = useRef(null);
  const [tab01Active, setTab01Active] = useState(false);
  const [tab02Active, setTab02Active] = useState(false);
  const [tab03Active, setTab03Active] = useState(false);
  const [tab04Active, setTab04Active] = useState(false);
  const [tab05Active, setTab05Active] = useState(false);
  const [tab06Active, setTab06Active] = useState(false);
  const [tab07Active, setTab07Active] = useState(false);
  const [tab08Active, setTab08Active] = useState(false);
  useEffect(() => {
    $(tab_01_ref.current).slideUp(0);
    $(tab_02_ref.current).slideUp(0);
    $(tab_03_ref.current).slideUp(0);
    $(tab_04_ref.current).slideUp(0);
    $(tab_05_ref.current).slideUp(0);
    $(tab_06_ref.current).slideUp(0);
    $(tab_07_ref.current).slideUp(0);
    $(tab_08_ref.current).slideUp(0);
  }, []);
  return (
    <section className="container global-pb global-pt">
      <div className={classes.faq}>
        <h2 className={[classes.title, "font-42"].join(" ")}>
          {t('support_faq_title')}
        </h2>
        <div className={classes.tabs}>
          <div
            className={classes.tab}
            onClick={() => {
              $(tab_01_ref.current).slideToggle(300);
              setTab01Active((value) => !value);
            }}
          >
            <div className={classes.tabTitle}>
              <h3 className={[classes.tabTitleText, "text-default"].join(" ")}>
                {t('support_faq_q_01_title')}
              </h3>
              <div
                className={`${classes.tabTitleStatus} ${
                  tab01Active && classes.activeStatus
                }`}
              ></div>
            </div>
            <div ref={tab_01_ref} className={classes.tabContent}>
              <p>
                {t('support_faq_q_01_text')}
              </p>
            </div>
          </div>
          <div
            className={classes.tab}
            onClick={() => {
              $(tab_02_ref.current).slideToggle(300);
              setTab02Active((value) => !value);
            }}
          >
            <div className={classes.tabTitle}>
              <h3 className={[classes.tabTitleText, "text-default"].join(" ")}>
              {t('support_faq_q_02_title')}
              </h3>
              <div
                className={`${classes.tabTitleStatus} ${
                  tab02Active && classes.activeStatus
                }`}
              ></div>
            </div>
            <div ref={tab_02_ref} className={classes.tabContent}>
              <p>
              {t('support_faq_q_02_text')}
              </p>
            </div>
          </div>
          <div
            className={classes.tab}
            onClick={() => {
              $(tab_03_ref.current).slideToggle(300);
              setTab03Active((value) => !value);
            }}
          >
            <div className={classes.tabTitle}>
              <h3 className={[classes.tabTitleText, "text-default"].join(" ")}>
              {t('support_faq_q_03_title')}
              </h3>
              <div
                className={`${classes.tabTitleStatus} ${
                  tab03Active && classes.activeStatus
                }`}
              ></div>
            </div>
            <div ref={tab_03_ref} className={classes.tabContent}>
              <p>
              {t('support_faq_q_03_text')}
              </p>
            </div>
          </div>
          <div
            className={classes.tab}
            onClick={() => {
              $(tab_04_ref.current).slideToggle(300);
              setTab04Active((value) => !value);
            }}
          >
            <div className={classes.tabTitle}>
              <h3 className={[classes.tabTitleText, "text-default"].join(" ")}>
              {t('support_faq_q_04_title')}
              </h3>
              <div
                className={`${classes.tabTitleStatus} ${
                  tab04Active && classes.activeStatus
                }`}
              ></div>
            </div>
            <div ref={tab_04_ref} className={classes.tabContent}>
              <p>
              {t('support_faq_q_04_text')}
              </p>
            </div>
          </div>
          <div
            className={classes.tab}
            onClick={() => {
              $(tab_05_ref.current).slideToggle(300);
              setTab05Active((value) => !value);
            }}
          >
            <div className={classes.tabTitle}>
              <h3 className={[classes.tabTitleText, "text-default"].join(" ")}>
              {t('support_faq_q_05_title')}
              </h3>
              <div
                className={`${classes.tabTitleStatus} ${
                  tab05Active && classes.activeStatus
                }`}
              ></div>
            </div>
            <div ref={tab_05_ref} className={classes.tabContent}>
              <p>
              {t('support_faq_q_05_text')}
              </p>
            </div>
          </div>
          <div
            className={classes.tab}
            onClick={() => {
              $(tab_06_ref.current).slideToggle(300);
              setTab06Active((value) => !value);
            }}
          >
            <div className={classes.tabTitle}>
              <h3 className={[classes.tabTitleText, "text-default"].join(" ")}>
              {t('support_faq_q_06_title')}
              </h3>
              <div
                className={`${classes.tabTitleStatus} ${
                  tab06Active && classes.activeStatus
                }`}
              ></div>
            </div>
            <div ref={tab_06_ref} className={classes.tabContent}>
              <p>
              {t('support_faq_q_06_text')}
              </p>
            </div>
          </div>
          <div
            className={classes.tab}
            onClick={() => {
              $(tab_07_ref.current).slideToggle(300);
              setTab07Active((value) => !value);
            }}
          >
            <div className={classes.tabTitle}>
              <h3 className={[classes.tabTitleText, "text-default"].join(" ")}>
              {t('support_faq_q_07_title')}
              </h3>
              <div
                className={`${classes.tabTitleStatus} ${
                  tab07Active && classes.activeStatus
                }`}
              ></div>
            </div>
            <div ref={tab_07_ref} className={classes.tabContent}>
              <p>
              {t('support_faq_q_07_text')}
              </p>
            </div>
          </div>
          <div
            className={classes.tab}
            onClick={() => {
              $(tab_08_ref.current).slideToggle(300);
              setTab08Active((value) => !value);
            }}
          >
            <div className={classes.tabTitle}>
              <h3 className={[classes.tabTitleText, "text-default"].join(" ")}>
              {t('support_faq_q_08_title')}
              </h3>
              <div
                className={`${classes.tabTitleStatus} ${
                  tab08Active && classes.activeStatus
                }`}
              ></div>
            </div>
            <div ref={tab_08_ref} className={classes.tabContent}>
              <p>
              {t('support_faq_q_08_text')}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Faq;
