import React, { useEffect, useRef } from "react";
import classes from "./Three.module.scss";
import img1 from "../../../assets/img/main/3/1.png";
import img2 from "../../../assets/img/main/3/2.png";
import img3 from "../../../assets/img/main/3/3.png";
import img4 from "../../../assets/img/main/3/4.png";
import img5 from "../../../assets/img/main/3/5.png";
import img6 from "../../../assets/img/main/3/6.png";
import { Trans, useTranslation } from "react-i18next";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const Three = () => {
  const { t } = useTranslation();
  const threeRef = useRef(null);
  useEffect(() => {
    if (document.documentElement.clientWidth > 1220) {
      gsap.timeline().fromTo(threeRef.current, {
        y: 100,
        opacity: 0,
      }, {
        y: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: threeRef.current,
          end: "+=200",
          scrub: 2,
        }
      });
    }
  }, []);
  return (
    <div className={[classes.three, "global-pb"].join(" ")}>
      <div ref={threeRef} className={[classes.threeBody, ""].join(" ")}>
        <div className="container">
          <div className={[classes.threeTitle, "font-42"].join(" ")}>
            {t('what_makes_title')}
          </div>
          <div className={[classes.row, ""].join(" ")}>
            <div className={[classes.rowColumn, ""].join(" ")}>
              <div className={[classes.rowColumnImg, ""].join(" ")}>
                <img src={img1} alt="" />
              </div>
              <div className={[classes.rowColumnText, "font-18"].join(" ")}>
                <Trans>
                  {t('what_makes_li_01')}
                </Trans>
              </div>
            </div>
            <div className={[classes.rowColumn, ""].join(" ")}>
              <div className={[classes.rowColumnImg, ""].join(" ")}>
                <img src={img2} alt="" />
              </div>
              <div className={[classes.rowColumnText, "font-18"].join(" ")}>
                <Trans>
                  {t('what_makes_li_02')}
                </Trans>
              </div>
            </div>
            <div className={[classes.rowColumn, ""].join(" ")}>
              <div className={[classes.rowColumnImg, ""].join(" ")}>
                <img src={img3} alt="" />
              </div>
              <div className={[classes.rowColumnText, "font-18"].join(" ")}>
                <Trans>
                  {t('what_makes_li_03')}
                </Trans>
              </div>
            </div>
            <div className={[classes.rowColumn, ""].join(" ")}>
              <div className={[classes.rowColumnImg, ""].join(" ")}>
                <img src={img4} alt="" />
              </div>
              <div className={[classes.rowColumnText, "font-18"].join(" ")}>
                <Trans>
                  {t('what_makes_li_04')}
                </Trans>
              </div>
            </div>
            <div className={[classes.rowColumn, ""].join(" ")}>
              <div className={[classes.rowColumnImg, ""].join(" ")}>
                <img src={img5} alt="" />
              </div>
              <div className={[classes.rowColumnText, "font-18"].join(" ")}>
                <Trans>
                  {t('what_makes_li_05')}
                </Trans>
              </div>
            </div>
            <div className={[classes.rowColumn, ""].join(" ")}>
              <div className={[classes.rowColumnImg, ""].join(" ")}>
                <img src={img6} alt="" />
              </div>
              <div className={[classes.rowColumnText, "font-18"].join(" ")}>
                <Trans>
                  {t('what_makes_li_06')}
                </Trans>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Three;
