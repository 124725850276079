import React, { useEffect, useRef } from "react";
import classes from './DiscoverNew.module.scss';
import chart from '../../../assets/img/TradingPage/DiscoverNew/chart.png';
import img from '../../../assets/img/TradingPage/DiscoverNew/img.png';
import img_blue from '../../../assets/img/TradingPage/DiscoverNew/img_blue.png';
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { t } from "i18next";
gsap.registerPlugin(ScrollTrigger);

const DiscoverNew = () => {
    const imgRef = useRef(null);
    const imgBlueRef = useRef(null);
    useEffect(() => {
        if (document.documentElement.clientWidth > 1220) {
            gsap.timeline().fromTo(imgRef.current, {
                x: 50,
            }, {
                x: 0,
                scrollTrigger: {
                    trigger: imgRef.current,
                    end: "+=400px",
                    scrub: 3,
                }
            });
            gsap.timeline().fromTo(imgBlueRef.current, {
                x: 150,
            }, {
                x: 0,
                scrollTrigger: {
                    trigger: imgRef.current,
                    end: "+=400px",
                    scrub: 3,
                }
            });
        }
    }, []);
    return (
        <div className={classes.discoverNewWr}>
            <section className="container">
                <div className={classes.discoverNew}>
                    <div className={classes.text}>
                        <p>
                            {t('discover_new_text_01')}
                            <b>&nbsp;{t('discover_new_text_02')}!</b>
                        </p>
                        <p>
                            {t('discover_new_text_03')}
                        </p>
                        <p>
                            <b>
                                {t('discover_new_text_04')}
                            </b>
                        </p>
                    </div>
                    <img className={classes.chart} src={chart} alt=''/>
                    <img ref={imgRef} className={classes.img} src={img} alt=''/>
                    <img ref={imgBlueRef} className={classes.img_blue} src={img_blue} alt=''/>
                </div>
            </section>
        </div>
    );
};

export default DiscoverNew;
