import React, { useEffect } from "react";
import Banner from "../../components/EducationPage/Banner/Banner";
import Faq from "../../components/EducationPage/Faq/Faq";
import HowToCreate from "../../components/EducationPage/HowToCreate/HowToCreate";
import HowToTrade from "../../components/EducationPage/HowToTrade/HowToTrade";
import MainTerms from "../../components/EducationPage/MainTerms/MainTerms";
import Tabs from "../../components/EducationPage/Tabs/Tabs";
import TopTrading from "../../components/EducationPage/TopTrading/TopTrading";
import Footer from "../../components/GlobalComponents/Footer/Footer";
import Header from "../../components/GlobalComponents/Header/Header";
import classes from './EducationPage.module.scss';

const EducationPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="ovf-hidden">
      <div className={classes.page}>
        <Header />
        <Banner />
        <HowToCreate />
        <TopTrading />
        <Tabs />
        <HowToTrade />
        <MainTerms />
        <Faq />
        <Footer />
      </div>
    </div>
  );
};

export default EducationPage;
