import React, { useEffect } from "react";
import classes from "./MainPage.module.scss";
import Header from "../../components/GlobalComponents/Header/Header";
import Footer from "../../components/GlobalComponents/Footer/Footer";
import First from "../../components/MainPage/First/First";
import Second from "../../components/MainPage/Second/Second";
import Three from "../../components/MainPage/Three/Three";
import Four from "../../components/MainPage/Four/Four";
import Five from "../../components/MainPage/Five/Five";
import Six from "../../components/MainPage/Six/Six";
import Seven from "../../components/MainPage/Seven/Seven";
import StartTrading from "../../components/MainPage/StartTrading/StartTrading";
import Faq from "../../components/MainPage/Faq/Faq";
import Last from "../../components/MainPage/Last/Last";
const MainPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className={classes.main}>
      <Header />
      <First />
      <Second />
      <Three />
      <Four />
      <Five />
      <Six />
      <Seven />
      <StartTrading />
      <Faq />
      <Last />
      <Footer />
    </div>
  );
};

export default MainPage;
