import React, { useEffect, useRef } from "react";
import classes from './OurMission.module.scss';
import img_01 from '../../../assets/img/AboutPage/OurMission/img_01.png';
import img_02 from '../../../assets/img/AboutPage/OurMission/img_02.png';
import { useTranslation } from "react-i18next";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const OurMission = () => {
    const { t } = useTranslation();
    const ourMissionRef = useRef(null);
    const ourMissionRef_02 = useRef(null);
    useEffect(() => {
        if (document.documentElement.clientWidth > 1220) {
            gsap.timeline().fromTo(ourMissionRef.current, {
                y: 100,
                opacity: 0,
            }, {
                y: 0,
                opacity: 1,
                scrollTrigger: {
                trigger: ourMissionRef.current,
                end: "+=200",
                scrub: 2,
                }
            });
            gsap.timeline().fromTo(ourMissionRef_02.current, {
                y: 100,
                opacity: 0,
            }, {
                y: 0,
                opacity: 1,
                scrollTrigger: {
                trigger: ourMissionRef_02.current,
                end: "+=200",
                scrub: 2,
                }
            });
        }
    }, []);
    return (
        <section className="container">
            <div className={classes.ourMission}>
                <div ref={ourMissionRef} className={classes.block}>
                    <div className={classes.content}>
                        <h2 className={[classes.title, 'font-42'].join(' ')}>
                            {t('our_mission_title')}
                        </h2>
                        <div className={[classes.text, 'font-20'].join(' ')}>
                            <p>
                                {t('our_mission_text_01')}
                                <br/>{t('our_mission_text_02')}
                            </p>
                            <ul>
                                <li>{t('our_mission_li_01')}</li>
                                <li>{t('our_mission_li_02')}</li>
                                <li>{t('our_mission_li_03')}</li>
                                <li>{t('our_mission_li_04')}</li>
                            </ul>
                            <p>
                                {t('our_mission_text_03')}
                            </p>
                        </div>
                    </div>
                    <div className={classes.image}>
                        <img className={classes.img_01} src={img_01} alt=''/>
                    </div>
                </div>
                <div ref={ourMissionRef_02} className={classes.block}>
                    <div className={classes.image}>
                        <img className={classes.img_02} src={img_02} alt=''/>
                    </div>
                    <div className={classes.content}>
                        <h2 className={[classes.title, 'font-42'].join(' ')}>
                            {t('our_aim_title')}
                        </h2>
                        <div className={[classes.text, 'font-20'].join(' ')}>
                            <ul>
                                <li>{t('our_aim_li_01')}</li>
                                <li>{t('our_aim_li_02')}</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default OurMission;
