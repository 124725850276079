import React, { useRef, useEffect, useState } from "react";
import classes from "./Faq.module.scss";
import { useTranslation } from "react-i18next";
import $ from "jquery";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const Faq = () => {
  const { t } = useTranslation();
  const tab_01_ref = useRef(null);
  const tab_02_ref = useRef(null);
  const tab_03_ref = useRef(null);
  const tab_04_ref = useRef(null);
  const [tab01Active, setTab01Active] = useState(false);
  const [tab02Active, setTab02Active] = useState(false);
  const [tab03Active, setTab03Active] = useState(false);
  const [tab04Active, setTab04Active] = useState(false);
  useEffect(() => {
    $(tab_01_ref.current).slideUp(0);
    $(tab_02_ref.current).slideUp(0);
    $(tab_03_ref.current).slideUp(0);
    $(tab_04_ref.current).slideUp(0);
  }, []);
  const faqMainRef = useRef(null);
  useEffect(() => {
    if (document.documentElement.clientWidth > 1220) {
      gsap.timeline().fromTo(faqMainRef.current, {
        y: 100,
        opacity: 0,
      }, {
        y: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: faqMainRef.current,
          end: "+=200",
          scrub: 2,
        }
      });
    }
  }, []);
  return (
    <section className="container global-pb">
      <div ref={faqMainRef} className={classes.faq}>
        <h2 className={[classes.title, "font-42"].join(" ")}>
          {t('home_faq_title')}
        </h2>
        <div className={classes.tabs}>
          <div
            className={classes.tab}
            onClick={() => {
              $(tab_01_ref.current).slideToggle(300);
              setTab01Active((value) => !value);
            }}
          >
            <div className={classes.tabTitle}>
              <h3 className={[classes.tabTitleText, "text-default"].join(" ")}>
                {t('home_faq_q_01_title')}
              </h3>
              <div
                className={`${classes.tabTitleStatus} ${
                  tab01Active && classes.activeStatus
                }`}
              ></div>
            </div>
            <div ref={tab_01_ref} className={classes.tabContent}>
              <p>
                {t('home_faq_q_01_text')}
              </p>
            </div>
          </div>
          <div
            className={classes.tab}
            onClick={() => {
              $(tab_02_ref.current).slideToggle(300);
              setTab02Active((value) => !value);
            }}
          >
            <div className={classes.tabTitle}>
              <h3 className={[classes.tabTitleText, "text-default"].join(" ")}>
                {t('home_faq_q_02_title')}
              </h3>
              <div
                className={`${classes.tabTitleStatus} ${
                  tab02Active && classes.activeStatus
                }`}
              ></div>
            </div>
            <div ref={tab_02_ref} className={classes.tabContent}>
              <p>
                {t('home_faq_q_02_text')}.
              </p>
            </div>
          </div>
          <div
            className={classes.tab}
            onClick={() => {
              $(tab_03_ref.current).slideToggle(300);
              setTab03Active((value) => !value);
            }}
          >
            <div className={classes.tabTitle}>
              <h3 className={[classes.tabTitleText, "text-default"].join(" ")}>
                {t('home_faq_q_03_title')}
              </h3>
              <div
                className={`${classes.tabTitleStatus} ${
                  tab03Active && classes.activeStatus
                }`}
              ></div>
            </div>
            <div ref={tab_03_ref} className={classes.tabContent}>
              <p>
                {t('home_faq_q_03_text')}
              </p>
            </div>
          </div>
          <div
            className={classes.tab}
            onClick={() => {
              $(tab_04_ref.current).slideToggle(300);
              setTab04Active((value) => !value);
            }}
          >
            <div className={classes.tabTitle}>
              <h3 className={[classes.tabTitleText, "text-default"].join(" ")}>
                {t('home_faq_q_04_title')}
              </h3>
              <div
                className={`${classes.tabTitleStatus} ${
                  tab04Active && classes.activeStatus
                }`}
              ></div>
            </div>
            <div ref={tab_04_ref} className={classes.tabContent}>
              <p>
                {t('home_faq_q_04_text')}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Faq;
